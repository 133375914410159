import React, {useEffect, useRef, useState} from "react";
import stringify from "json-stable-stringify";
import {useSearchParams} from "react-router-dom";
import {IoIosFlag} from "react-icons/io";
import {RiArrowLeftRightFill} from "react-icons/ri";
import {FaHockeyPuck} from "react-icons/fa";
import Config from "./Config";
import { useCheckIsAdmin } from "./UserGroups";
import {useSWRConfig} from "swr";
import red_card from "../img/red_card.svg"
import yellow_card from "../img/yellow_card.svg"
import {
    GiCornerFlag,
    GiGoalKeeper,
    GiHockey,
    GiMedicalPackAlt,
    GiSoccerBall,
    GiSoccerKick,
    GiWhistle
} from "react-icons/gi";

export function objToQuerystring (params, prefix="?") {
    let keys = Object.keys(params);
    if (!keys) return "";
    keys = keys.filter(key => params[key] !== undefined);
    keys.sort();
    return prefix + keys.map(key => {
        let value = params[key];
        if (value instanceof Object) value = stringify(value);
        return encodeURIComponent(key) + "=" + encodeURIComponent(value)
    }).join("&");
}

export function querystringToObj (params) {
    // Input must be either string, e.g. "a=12&b=twelve" or a URLSearchParam
    if (!(params instanceof URLSearchParams))
        params = new URLSearchParams(params);
    return Object.fromEntries(params);
}

export function formatTimecode (duration, withHour=undefined) {
    if (isNaN(duration)) return "00:00";
    if (withHour === undefined) withHour = duration >= 3600;
    let sec = Math.floor(duration);
    let min = Math.floor(sec / 60);
    let hour = Math.floor(min / 60);
    sec %= 60;
    min %= 60;
    if (withHour) return ("0" + hour).slice(-2) + ":" +  ("0" + min).slice(-2) + ":" + ("0" + sec).slice(-2);
    return ("0" + min).slice(-2) + ":" + ("0" + sec).slice(-2);
}

export function getTimelineClickRatio (e, target=undefined) {
    target = target || e.target;
    const boundingBox = target.getBoundingClientRect();
    const x = e.clientX - boundingBox.left;
    // console.log(Math.max(0, Math.min(1, x / boundingBox.width)));
    return Math.max(0, Math.min(1, x / boundingBox.width));
}

export function capitalizeFirstLetter (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getScrollParent (element, includeHidden) {
    let style = getComputedStyle(element);
    const excludeStaticParent = style.position === "absolute";
    const overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;

    if (style.position === "fixed") return document.body;
    for (let parent = element; (parent = parent.parentElement);) {
        style = getComputedStyle(parent);
        if (excludeStaticParent && style.position === "static") {
            continue;
        }
        if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) return parent;
    }

    return document.body;
}

export function duplicateSearchParamsSubset (params=[], searchParams) {
    return objToQuerystring(params.reduce((aggregate, key) => {
        const value = searchParams.get(key);
        if (value) aggregate[key] = value;
        return aggregate;
    }, {}));
}

export function throttled (func, limit) {
    /* Simple function for making sure a function doesnt get called more than every "limit" ms. Final value always called */
    let disabled = false;
    let pending = null;

    function inner () {
        if (disabled) pending = arguments;
        else {
            disabled = true;
            func.apply(this, arguments);
            setTimeout(() => {
                const p = pending;
                disabled = false;
                pending = null;
                if (p !== null) inner.apply(this, p);
            }, limit);
        }
    }
    return inner;
}

export function useUpdateSearchParams () {
    /*
    Small wrapper around React-DOM's useSearchparams.
    Adding the functionality of being able to set either individual values or a bunch at the same time,
    without overwriting the existing parameters. Setting a param to null will remove it.
    examples: updateSearchParam("x", 12), updateSearchParam({x: 12, y: null})
     */
    const [searchParams, setSearchParams] = useSearchParams();

    function updateSearchParams (key, value=null) {
        if (key instanceof Object) {
            for (const [k, v] of Object.entries(key)) {
                // console.log("key:", key, "value:", value);
                // console.log("k: "+ k, "v: "+ v);
                if (v === null) searchParams.delete(k);
                else searchParams.set(k, v);
            }
        } else {
            if (value === null) searchParams.delete(key);
            else searchParams.set(key, value);
        }
        setSearchParams(searchParams);
        return false;
    }

    function resetAllSearchParamsExcept (whitelisted=[]) {
        setSearchParams(whitelisted.reduce((aggregate, key) => {
            const value = searchParams.get(key);
            if (value) aggregate[key] = value;
            return aggregate;
        }, {}));
    }

    return [
        searchParams,
        updateSearchParams,
        resetAllSearchParamsExcept,
    ];
}

export function getDisplayDuration (duration) {
    if (isNaN(duration)) return "00:00";
    let sec = Math.floor(duration / 1000);
    let min = Math.floor(sec / 60);
    let hour = Math.floor(min / 60);
    sec %= 60;
    min %= 60;
    return (hour > 0 ? hour + ":" : "") + ("0" + min).slice(-2) + ":" + ("0" + sec).slice(-2);
}

export function toThumbnailUrl (assetName, offset) {
    function padString (input, pad, places) {
        const str = input.toString();
        const padding = pad.repeat(places);
        return padding.substring(0, padding.length - str.length) + str;
    }

    let baseUrl = assetName;
    let extension = ".jpg";
    let leadingZeroes = 5;
    let seconds = Math.floor(offset / 1000);
    const thumbRegex = /(.+\/(?:img_?.)?)(\d+)\.jpg$/;

    // Most assets dont start at offset 0, name points to *img.00123.jpg
    let regexMatch = baseUrl.match(thumbRegex);
    if (regexMatch !== null) {
        baseUrl = regexMatch[1];
        let offsetStr = regexMatch[2];
        leadingZeroes = offsetStr.length;
        seconds += parseInt(offsetStr, 10);
    } else {
        baseUrl += "/";
    }

    const sequenceNumber = padString(Math.max(0, seconds), "0", leadingZeroes);

    return `${baseUrl}${sequenceNumber}${extension}`;
}

export function useLocalStorage (key, fallback=null, timeToLiveSeconds=3600) {
    const init = () => {
        const initialData = localStorage.getItem(key);
        if (!initialData) return fallback;

        try {
            const parsedData = JSON.parse(initialData);
            if (timeToLiveSeconds) {
                const expiration = new Date();
                expiration.setSeconds(expiration.getSeconds() - timeToLiveSeconds);
                if (parsedData.edited < expiration.toISOString()) {
                    // Key has expired
                    localStorage.removeItem(key);
                    return fallback;
                }
            }

            return parsedData.data;
        } catch (e) {
            console.warn("Caught error parsing local storage data:", e);
        }
        return fallback;
    }

    const [data, setData] = useState(init);

    const update = (value) => {
        try {
            localStorage.setItem(key, JSON.stringify({
                edited: (new Date()).toISOString(),
                data: value,
            }));
        } catch (e) {
            console.warn("Caught error updating local storage data:", e);
        }
        return setData(value);
    }

    const reset = () => {
        try {
            localStorage.removeItem(key);
        } catch (e) {
            console.warn("Caught error resetting local storage data:", e);
        }
        return setData(fallback);
    }

    return [data, update, reset];
}

export function useMutateByRegex () {
    const { cache, mutate } = useSWRConfig()
    return (matcher, ...args) => {
        if (!(cache instanceof Map)) {
            throw new Error("useMutateByRegex requires the cache provider to be a Map instance")
        }

        const keys = []
        for (const key of cache.keys()) {
            if (matcher.test(key)) {
                keys.push(key)
            }
        }

        const mutations = keys.map((key) => mutate(key, ...args))
        return Promise.all(mutations)
    }
}

export function useIsMount () {
    // Why on earth doesn't react have a useEffect without initial mount...
    // Anyways, way to know inside render if this is the first render. Mostly useful inside useEffect
    // WARN: Hook must be used before any useEffects
    const isMountRef = useRef(true);
    useEffect(() => {
        isMountRef.current = false;
    }, []);
    return isMountRef.current;
}

export function tagToIcon (tag) {
    // TODO Need more icons
    switch (tag) {
        case "goal":
            return <GiSoccerBall/>
        case "shot":
            return <GiSoccerKick/>
        case "yellow card":
            return <img src={yellow_card} alt="card" style={{height: "20px"}}/>
        case "red card":
            return <img src={red_card} alt="card" style={{height: "20px"}}/>
        default:
            return <GiWhistle/>
    }
}

export function tagToIconShl (tag) {
    switch (tag) {
        case "goal":
            return <FaHockeyPuck style={{fontSize: "20px"}}/>
        case "shot":
            return <GiHockey/>
        case "shootoutpenaltyshot":
            return <GiHockey/>
        case "goalkeeperevent":
            return <GiGoalKeeper/>
        default:
            return <GiWhistle/>
    }
}

// TODO is separate function for timeline necessary?
export function tagToIconTimeline (tag) {
    
    const isScore = /[0-9]/.test(tag)
    if (isScore) return tag

    switch (tag) {
        case "shot":
            return <GiSoccerBall style={{height: "13px", margin: "-5px 0"}}/>
        case "corner":
            return <GiCornerFlag/>
        case "offside":
            return <IoIosFlag/>
        case "substitution":
            return <RiArrowLeftRightFill/>
        case "medical treatment":
            return <GiMedicalPackAlt/>
        case "yellow card":
            return <img src={yellow_card} alt="card" style={{height: "17px", margin: "-10px 0"}}/>
        case "red card":
            return <img src={red_card} alt="card" style={{height: "17px", margin: "-10px 0"}}/>
        default:
            return <GiWhistle style={{fontSize: "15px"}}/>
    }
}

// TODO is separate function for timeline necessary?
export function tagToIconShlTimeline (tag) {
    
    const isScore = /[0-9]/.test(tag)
    if (isScore) return tag

    switch (tag) {
        case "shot":
            return <FaHockeyPuck style={{fontSize: "8px", margin: "-10px 0"}}/>
        case "shootoutpenaltyshot":
            return <FaHockeyPuck style={{fontSize: "8px", margin: "-10px 0"}}/>
        case "goalkeeperevent":
            return <GiGoalKeeper style={{fontSize: "15px"}}/>
        default:
            return <GiWhistle style={{fontSize: "15px"}}/>
    }
}

export function hockeyTimeFormat (tag, hidePeriod=false) {
    if (!tag) return 0

    // TODO Replace with full timecode, e.g 00:00, like this:
    // const time = formatTimecode(tag?.game_time?.value)
    const gameTime = tag?.game_time?.value
    const time = gameTime? Math.ceil(tag.game_time.value / 60) + "'" : ""

    if (hidePeriod) return time

    // TODO Tag was temporarily renamed - decide which to use
    const period = tag?.period?.value || tag?.phase?.value
    switch (period) {
        case "1st period":
            return `P1 ${time}`
        case "2nd period":
            return `P2 ${time}`
        case "3rd period":
            return `P3 ${time}`
        case "overtime":
            return `OT ${time}`
        case "penalties":
            return `PE ${time}`
        default:
            return time || "-"
    }
}

export function useAddVideoToAction () {

    const [selectedVideos, setSelectedVideos] = useState(new Map());
    const [selectAll, setSelectAll] = useState(false);

    const addVideoToList = (playlist) => {
        setSelectedVideos(oldList => {
            const map = new Map(oldList);
            if (Array.isArray(playlist)) playlist.forEach(p => map.set(p.id, p));
            else map.set(playlist.id, playlist);
            return map;
        });
    }

    const removeVideoFromList = (id) => {
        setSelectedVideos((old) => {
            const map = new Map(old);
            map.delete(id);
            return map;
        });
        setSelectAll(false);
    }

    const clearSelectedList = () => {
        setSelectedVideos(new Map());
        setSelectAll(false);
    }

    const handleSelectAll = () => {
        if (selectAll) clearSelectedList();
        else setSelectAll(true);
    }

    return [
        selectedVideos, 
        selectAll,
        addVideoToList, 
        removeVideoFromList, 
        clearSelectedList, 
        handleSelectAll
    ]
}

export function createPlaybackUrl (clip, playbackUrl=null, padding=0, maximum=0, assetDuration=null) {
    const backend = Config.getBackend()
    padding = parseInt(padding)

    let urlBase = `${backend}/playlist.m3u8/`

    // adding sso key to the url 
    if (playbackUrl) {
        const hasSSoStreamingUrl = playbackUrl.includes("sso")
        if (hasSSoStreamingUrl) {
            const ssoKey = playbackUrl.split("/sso")[1].split("/")[1]
            if (!ssoKey) return
            else urlBase += `sso/${ssoKey}/`
        }
    }
    
    if (padding > 0 && maximum > 0) {
        const d = (clip.to_timestamp - clip.from_timestamp) / 1000
        // If the maximum is set, we don't want to add so much padding that we go above maximum.
        // We also want padding to be a multiple of 2 seconds
        const availablePadding = Math.floor(Math.max(0, maximum - d) / 4) * 2
        padding = Math.min(padding, availablePadding)
    }
    const msPadding = Math.max(0, padding * 1000)
    const fromTs = Math.max(0, clip.from_timestamp - msPadding)
    let toTs = clip.to_timestamp + msPadding

    if (assetDuration) {
        const maximumToTimestampExtension = assetDuration && assetDuration - clip.to_timestamp
        toTs = Math.min(clip.to_timestamp + maximumToTimestampExtension, clip.to_timestamp + msPadding)
    } 

    return {
        padding: padding,
        duration: (toTs - fromTs) / 1000,
        url: `${urlBase}${clip.video_asset_id}:${fromTs}:${toTs}/Manifest.m3u8`,
    };
}

export function showErrorMessage (message="", enable=false) {
    if (!enable) return null
    else return <div className="warning-msg">{message}</div>
}

// TODO rename this to "use..."
export function ClickOutside (ref, setDropdown) {
    useEffect(() => {
        const clickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)){
                setDropdown(false);
            }
        };
        document.addEventListener("mousedown", clickOutside);
        return () => document.removeEventListener("mousedown", clickOutside);
    });
}

export function getDateFormat (date, withTime=false, withoutSeconds=false) {
    // We use swedish because A) they are the primary users, B) they use a sensible YYYY-MM-DD hh:dd:ss format
    if (withTime) {
        if (withoutSeconds){
            const dateAndTime = new Date(date).toLocaleString("sv-SV")
            const dateTimeWithoutSeconds = dateAndTime.substring(0, dateAndTime.length-3)
            return dateTimeWithoutSeconds
        } 
        return new Date(date).toLocaleString("sv-SV")
    } 
    return new Date(date).toLocaleDateString("sv-SV")
}

export function getSizeFormat (bytes) {
    if (bytes < 0 && !+bytes) return "0 MB"
    
    const k = 1024
    const sizes = ["Bytes", "KB", "MB", "GB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(1))} ${sizes[i]}`
}

// TODO might be not needing this anymore when graphic package is only available for admins
export function useCheckGraphicPackagesPermission () {
    const [,isAdmin] = useCheckIsAdmin()
    const isSef = Config.association === "SEF"
    if (isSef) {
        if (isAdmin) return true
        else return false
    }
    else return true
}

export function toAscii (s) {
    return s.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
}

// Utility function borrowed from
// https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f
export function copyToClipboard (str) {
    const el = document.createElement("textarea");  // Create a <textarea> element
    el.value = str;                                 // Set its value to the string that you want copied
    el.setAttribute("readonly", "");                // Make it readonly to be tamper-proof
    el.style.position = "absolute";
    el.style.left = "-9999px";                      // Move outside the screen to make it invisible
    document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
    const selected =
        document.getSelection().rangeCount > 0      // Check if there is any content selected previously
            ? document.getSelection().getRangeAt(0) // Store selection if found
            : false;                                // Mark as false to know no selection existed before
    el.select();                                    // Select the <textarea> content
    document.execCommand("copy");                   // Copy - only works as a result of a user action (e.g. click events)
    document.body.removeChild(el);                  // Remove the <textarea> element
    if (selected) {                                 // If a selection existed before copying
        document.getSelection().removeAllRanges();  // Unselect everything on the HTML document
        document.getSelection().addRange(selected); // Restore the original selection
    }
}